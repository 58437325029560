/* Reset some default styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Monstrait', sans-serif; /* Apply the Monstrait font */
  font-size: 14px; /* Adjust font size as needed */
  height: 100%;
 }

/* WhatsApp-like styling */
.chat-app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.chat-window {
  padding-top:70px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  overflow-y: auto;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.message {
  position: relative; /* To position timestamp within the message */
  padding: 8px;
  border-radius: 8px;
}

.message-timestamp {
  width: 100%; /* Full width */
  text-align: end; /* Align timestamp to the right */
  font-size: 10px; /* Adjust font size as needed */
  color: #aaa; /* Timestamp color */
  margin-top: 4px; /* Add some spacing between message and timestamp */
}

.user {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.ads {
  background-color: red;
  align-content: center;
}

.bot,.receipt {
  background-color: #dcf8c6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.04), 0 16px 16px rgba(0, 0, 0, 0.04);
  align-self: flex-start;
}
.ads-content {
  background-color: #dcf8c6;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 400px;
  margin: auto; /* Centers horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.user-input form {
  display: flex;
  width: 100%;
}

.user-input input {
  flex-grow: 1;
  border: none;
  padding: 8px;
  border-radius: 20px;
  outline: none;
  font-size: 14px;
}

.user-input button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 20px;
  padding: 0;
  font-size: 16px;
}

.navbar {
  justify-content: space-between;
}

/* Your custom styles */
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for color changes */
}
.app.light{
  background-image: url("https://ik.imagekit.io/paysnug/web%20asset/psgbotwhatnew.png?updatedAt=1693400501078");
  background-repeat: no-repeat;
  background-size: cover;
}

.app.dark {
  background-image: url("https://ik.imagekit.io/paysnug/web%20asset/psgbotbackblacl.png?updatedAt=1693400123344");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;

}

.navbar.dark {
  background-color: #212121 !important;
}

.logo {
height: 50px;
  padding: 10px; /* Add padding to the logo */
}

.mode-toggle {
  cursor: pointer;
  padding: 10px; /* Add padding to the mode toggle */
}

/* Chat messages background */
.message {
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s; /* Smooth transition for background color */
}



/* Light mode input box */
.user-input.light input {
  background-color: white;
  color: black;
}

/* Dark mode input box */
.user-input.dark input {
  background-color: #212121;
  color: white;
}

.user-input{
  padding: 10px;
  position: sticky;
  bottom: 0;
}

.user-input.light{
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ccc;
  position: sticky;
  bottom: 0;
}
.message{
  margin-bottom: 15px;
}

.user-input.dark{
  padding: 10px;
  background-color: #212121;
  border-top: 1px solid #584545;
  position: sticky;
  bottom: 0;
}
/* Rest of your styles */

.message.dark.user{
  padding: 10px;
  background-color: #212121;
  border-top: 1px solid #584545;
  bottom: 0;
}
.message.dark.bot, .message.dark.receipt{
  padding: 10px;
  background-color: #212121;
  border-top: 1px solid #584545;
  bottom: 0;
}
/* Rest of your styles */

.message.user {
  background-color: #ebebf2;
}

.message.bot, .message.receipt {
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.04), 0 16px 16px rgba(0, 0, 0, 0.04);
}



.chat-window {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%; /* The chat window should take the full height of the parent container */
  overflow-y: auto; /* Enable vertical scrolling */
}

.message {
  word-wrap: break-word; 
  max-width: 30%;
}

/* Media query for smaller devices */
@media (max-width: 767px) {
  .message {
    max-width: 80%;
  }
}

.user-input-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.message {
  margin-left: 10px; 
  margin-right: 10px; 
}

.fontred{
  color: red;
  font-size: 10px;
}


/* Add this CSS to your stylesheet to style the PDF template */
.pdf-template {
  align-items: center;
  margin: 10px;
}

.pdf-template.user {
  justify-content: flex-end;
}

.pdf-icon {
  font-size: 24px;
  margin-right: 8px;
}

.pdf-info {
  background-color: #DCF8C6;
  padding: 10px;
  border-radius: 10px;
}

.pdf-name {
  font-weight: bold;
}

.pdf-actions a {
  margin-right: 10px;
  text-decoration: none;
  color: #0078D4;
}

.pdf-actions a:hover {
  text-decoration: underline;
}


.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Adjust as needed */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 450px; /* Limits max width */
  position: relative;
}

.ad-image {
  width: 100%;  /* Makes the image fit inside the container */
  height: auto; /* Maintains the aspect ratio */
  border-radius: 10px;
  max-height: 300; /* Prevents the image from being too tall */
  object-fit: cover; /* Ensures a clean display */
}


.close-btn {
  background: red;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn:hover {
  background: darkred;
}

.trigger-btn {
  background: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
}

.trigger-btn:hover {
  background: darkblue;
}
